<template>
  <SystemPageWrapper>
    <div
      v-if="isInvited"
      class="system-page__icon"
    >
      <icon
        data="@icons/style_error_double.svg"
        class="size-56 fill-off"
      />
    </div>
    <h1 class="system-page__title heading-2-md grey-100">
      {{ title }}
    </h1>
    <span class="system-page__subtitle body-1 grey-70">
      {{ subtitle }}
    </span>
    <div
      v-if="canViewExamples"
      class="system-page__links"
    >
      <SlLinkCard
        v-if="canViewExamples"
        :title="$t('Web.GetStarted.ExamplesTitle')"
        :description="$t('Web.GetStarted.ExamplesDescr')"
        to="/p/projects/examples"
      >
        <template #icon>
          <icon
            data="@icons/files.svg"
            class="size-72 fill-off"
          />
        </template>
      </SlLinkCard>
    </div>
    <div class="system-page__link">
      <SlLink
        color="primary"
        @click.prevent="login"
      >
        {{ $t('Web.GetStarted.SwitchAcc') }}
      </SlLink>
    </div>
  </SystemPageWrapper>
</template>

<script>
import { ContextHolder } from '@frontegg/vue';
import { noAccessReasons } from '@/config/router/noAccess.config';

export default {
  name: 'NoAccess',
  data() {
    return {
      titleByReason: {
        [noAccessReasons.NO_ACCESS]: this.$t('Web.NoAccess.NotInvitedTitle'),
        [noAccessReasons.LICENSE_EXPIRED]: this.$t('Web.LicenseDetails.NoAccessTitle'),
        [noAccessReasons.LICENSE_INCOMPLETE]: this.$t('Web.NoAccess.WrongMetaTitle'),
        [noAccessReasons.ERROR_ON_SETUP]: this.$t('Web.NoAccess.TryLaterTitle'),
        [noAccessReasons.WRONG_TENANT]: this.$t('Web.Error.WrongTenant'),
        [noAccessReasons.AUTH_FAILED]: this.$t('Web.Error.AuthFailed'),
        [noAccessReasons.CANT_SETUP_SERVER]: this.$t('Web.NoAccess.AdminActivation')
      },
      subtitleByReason: {
        [noAccessReasons.NO_ACCESS]: this.$t('Web.NoAccess.NotInvitedText'),
        [noAccessReasons.LICENSE_EXPIRED]: this.$t('Web.LicenseDetails.ContactAdminToRestore'),
        [noAccessReasons.LICENSE_INCOMPLETE]: this.$t('Web.NoAccess.NotInvitedText'),
        [noAccessReasons.WRONG_TENANT]: this.$t('Web.NoAccess.NotInvitedText'),
        [noAccessReasons.AUTH_FAILED]: this.$t('Web.NoAccess.NotInvitedText')
      },
      noExamplesErrors: [
        noAccessReasons.LICENSE_EXPIRED,
        noAccessReasons.LICENSE_INCOMPLETE,
        noAccessReasons.WRONG_TENANT,
        noAccessReasons.AUTH_FAILED
      ]
    };
  },
  computed: {
    reason() {
      return this.$route.query.reason ?? noAccessReasons.NO_ACCESS;
    },
    isInvited() {
      return this.reason !== noAccessReasons.NO_ACCESS;
    },
    title() {
      return this.titleByReason[this.reason];
    },
    subtitle() {
      return this.subtitleByReason[this.reason];
    },
    canViewExamples() {
      return !this.noExamplesErrors.includes(this.reason);
    }
  },
  methods: {
    login() {
      const baseUrl = ContextHolder.getContext().baseUrl;

      window.location.replace(`${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/shared/page/system-page/no-access.scss";
@import "@/style/components/shared/page/system-page/get-started.scss";
</style>
